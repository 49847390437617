import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactDOMServer from 'react-dom/server'
import * as THREE from 'three'
import loadable from '@loadable/component'
import { useCallbackRef } from 'use-callback-ref'

import globeData from './data/globe-data-min.json'
import locations from './data/locations.json'
import paths from './data/paths.json'

const ReactThreeGlobe = loadable(async () => import('react-globe.gl'))

export const Globe = () => {
  const [mounted, setMounted] = useState(false)

  const globeMaterial = new THREE.MeshPhongMaterial()
  globeMaterial.color = new THREE.Color('#6000c2')
  globeMaterial.emissive = new THREE.Color('#220038')
  globeMaterial.emissiveIntensity = 0.7
  globeMaterial.shininess = 0.7

  const globeEl = useCallbackRef(null, () => {
    if (globeEl && globeEl.current && !mounted) {
      setMounted(true)

      setTimeout(() => {
        globeEl.current.controls().autoRotate = true
        globeEl.current.controls().autoRotateSpeed = -0.5
        globeEl.current.controls().enableZoom = false
        globeEl.current.pointOfView({ lat: 25, lng: -50, altitude: 2.25 })

        const directionalLight = globeEl.current
          .scene()
          .children.find(obj3d => obj3d.type === 'DirectionalLight')
        if (directionalLight) {
          directionalLight.visible = false
        }

        const ambientLight = globeEl.current
          .scene()
          .children.find(obj3d => obj3d.type === 'AmbientLight')
        if (ambientLight) {
          ambientLight.visible = false
        }

        const dLight = new THREE.DirectionalLight('#418F9F', 1)
        dLight.position.set(-6000, 2000, 2000)
        const dLight1 = new THREE.DirectionalLight('#418f9f', 1)
        dLight1.position.set(-6000, 2000, 5500)
        const dLight2 = new THREE.PointLight('#418F9F', 0.5)
        dLight2.position.set(-6000, 2000, 200)

        globeEl.current.camera().add(dLight)
        globeEl.current.camera().add(dLight1)
        globeEl.current.camera().add(dLight2)

        globeEl.current.scene().fog = new THREE.Fog('#418f9f', 400, 2000)
        globeEl.current.scene().add(new THREE.AmbientLight(0xbbbbbb, 0.1))

        const newPersCamera = globeEl.current.camera()
        globeEl.current.scene().add(newPersCamera)

        globeEl.current.renderer().setClearColor(0xffffff, 0)
      }, 700)
    }
  })

  const labelWindow = data => {
    return ReactDOMServer.renderToString(
      <div className="label-window">
        <div className="label-company">{data.companyName}</div>
        <div className="label-desc">{data.description}</div>
      </div>
    )
  }

  return (
    <ReactThreeGlobe
      ref={globeEl}
      rendererConfig={{ antialias: false, alpha: true }}
      showAtmosphere={true}
      atmosphereColor="#418F9F"
      atmosphereAltitude={0.09}
      width={750}
      height={750}
      backgroundColor="#21242C"
      globeMaterial={globeMaterial}
      hexPolygonsData={globeData.features}
      hexPolygonColor={() => '#FFF'}
      hexPolygonResolution={3}
      hexPolygonMargin={0.7}
      pointsData={locations.locations}
      pointColor={e => {
        if (e.text === 'BIE') {
          return '#EF466F'
        } else {
          return '#4BC9F0'
        }
      }}
      pointAltitude={0.1}
      pointRadius={0.2}
      arcsData={paths.paths}
      arcColor={d => {
        return ['#FF5F7A', '#FFD166']
      }}
      arcAltitude={e => {
        return e.arcAlt
      }}
      arcStroke={() => 0.8}
      arcDashLength={1}
      arcDashAnimateTime={3000}
      arcsTransitionDuration={0}
      arcDashInitialGap={e => Math.random() * 1 + 1}
      arcDashGap={e => Math.random() * 1 + 1}
      arcLabel={d => {
        return labelWindow(d.data)
      }}
      pointLabel={d => {
        return labelWindow(d.data)
      }}
      onPointHover={(point, prevPoint) => {
        if (point) {
          document.body.style.cursor = 'pointer'
          globeEl.current.controls().autoRotate = false
        } else if (!point) {
          document.body.style.cursor = 'auto'
          globeEl.current.controls().autoRotate = true
        }
      }}
      onPointClick={(point, event) => {
        event.preventDefault()
        if (point.url) {
          window.open(point.url, '_blank')
        }
      }}
      onArcHover={(arc, prevArc) => {
        if (arc) {
          document.body.style.cursor = 'pointer'
          globeEl.current.controls().autoRotate = false
        } else if (!arc) {
          document.body.style.cursor = 'auto'
          globeEl.current.controls().autoRotate = true
        }
      }}
      onArcClick={(arc, event) => {
        event.preventDefault()
        if (arc.url) {
          window.open(arc.url, '_blank')
        }
      }}
    />
  )
}
