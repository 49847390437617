import styled from 'styled-components'
import { motion } from 'framer-motion'
import { typeScale, neutral } from 'styles'

export const AnimatedScreenWrapper = styled(motion.div)`
  z-index: 10;
  width: 555px;
  height: 360px;
  filter: drop-shadow(0px 16px 24px rgba(17, 37, 62, 0.16));
  backdrop-filter: blur(20px);
  border-radius: 12px;
  background: #ffffff;
  opacity: 0.54;
  position: -webkit-sticky;
  position: sticky;
  left: 50%;
  top: 30%;

  &.works {
    width: 630px;
    margin-top: 100px;
    background: rgba(56, 57, 61, 0.57);
    opacity: 0.64;
  }
`

export const UrlBar = styled(motion.div)`
  position: absolute;
  width: 370px;
  height: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  top: 15px;
  left: 18%;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: ${neutral[500]};
    font-size: ${typeScale.copyrightText};
    text-decoration: none;
  }
`

export const Sidebar = styled(motion.div)`
  position: absolute;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08);
  top: 55px;
  left: 15px;
  width: 100px;
  height: 290px;
  border-radius: 12px;

  &.works {
    background: #21242c;
    width: 178px;
  }
`

export const SidebarContentWrapper = styled(motion.div)`
  padding: 0.75rem;

  .projects-title {
    color: ${neutral[300]};
  }

  .projects-description {
    color: ${neutral[400]};
    font-size: ${typeScale.helperText};
    margin-top: 5px;
  }
`

export const SidebarItem = styled(motion.span)`
  margin-bottom: 0.5rem;
  display: block;
  font-size: ${typeScale.helperText};
  font-weight: 600;
  color: ${neutral[500]};
  cursor: pointer;
`

export const MainWindow = styled(motion.div)`
  position: absolute;
  width: 403px;
  height: 290px;
  left: 133px;
  top: 55px;
  pointer-events: none;

  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  &.works {
    background: #21242c;
    left: 210px;
  }

  .project-img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`
export const MainWindowContentWrapper = styled(motion.div)`
  padding: 0.75rem;
`

export const AboutContent = styled.div`
  position: relative;
  user-select: none;
  pointer-events: none;

  .profile-background {
    background-image: radial-gradient(
      40.12% 1864.16% at 7.09% 4.38%,
      #6941ff 0%,
      #ef466f 100%
    );
    width: 100%;
    height: 70px;
    border-radius: 12px;
    border-radius: 12px 12px 0 0;
  }

  .profile-picture {
    background: #fff;
    border-radius: 50%;
    position: absolute;
    padding: 5px 15px 0px 15px;
    top: 37px;
    left: 15px;
    border: 1px solid ${neutral[400]};
  }

  .profile-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px 10px 10px 10px;
    border: 1px solid ${neutral[400]};
    border-radius: 0 0 12px 12px;

    span {
      display: block;
    }

    .name {
      font-weight: 600;
    }

    .desc {
      font-size: ${typeScale.helperText};
    }

    .location {
      font-size: ${typeScale.copyrightText};
      color: ${neutral[500]};
    }

    .current {
      font-size: ${typeScale.copyrightText};
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .profile-info {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid ${neutral[400]};
    border-radius: 12px;

    span {
      display: block;
    }

    .info-header {
      font-size: ${typeScale.navText};
      margin-bottom: 3px;
    }

    .info-text {
      font-size: ${typeScale.copyrightText};
    }
  }
`

export const ExperienceContent = styled.div`
  .exp-header {
  }

  .entry {
    display: flex;
    margin: 10px 0;
    font-size: ${typeScale.navText};
  }

  .entry-img {
    margin-right: 10px;
  }

  .entry-header {
    font-weight: 600;
    display: block;
  }

  .entry-location {
    font-size: ${typeScale.copyrightText};
  }

  .entry-time {
    font-size: ${typeScale.copyrightText};
    color: ${neutral[500]};
    margin-left: 5px;
  }
`

export const TechStackContent = styled.div`
  .exp-subheader {
    color: ${neutral[500]};
    font-size: ${typeScale.navText};
    border-bottom: 1px solid ${neutral[400]};
    display: block;
    margin: 15px 0;
  }

  .exp-list {
    margin: 15px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
  }

  .list-item {
    font-size: ${typeScale.copyrightText};
    font-weight: 600;
  }
`
