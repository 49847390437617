import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Typist from 'react-typist'

import { GitBranchWrapper, Line, GitCommandWrapper } from './Git.style'
import BranchOutline from 'assets/images/branch-outline.svg'
import TerminalIcon from 'assets/images/terminal-icon.svg'

export const GitBranch = ({ branchName = 'develop' }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  const circleVariants = {
    initial: {
      scale: 0.9,
      opacity: 0,
    },
    visible: { scale: 1, opacity: 1 },
  }

  const branchVariants = {
    initial: {
      x: 20,
      opacity: 0,
    },
    visible: { x: 0, opacity: 1 },
  }

  return (
    <GitBranchWrapper ref={ref}>
      {inView ? (
        <>
          <motion.div
            className="circle"
            variants={circleVariants}
            initial="initial"
            animate="visible"
            transition={{ duration: 0.7, cubicBezier: [0.16, 1, 0.3, 1] }}
          />
          <motion.div
            className="branch"
            variants={branchVariants}
            initial="initial"
            animate="visible"
            transition={{ duration: 0.7, cubicBezier: [0.16, 1, 0.3, 1] }}
          >
            <BranchOutline className="outline" width={12} height={12} />
            <span>{branchName}</span>
          </motion.div>
        </>
      ) : null}
    </GitBranchWrapper>
  )
}

export const GitLine = ({ height = 50, style, className }) => {
  return <Line className={className} style={style} height={height} />
}

export const GitCommand = ({ text = 'git checkout develop', style }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  const commandVariants = {
    initial: {
      x: 20,
      opacity: 0,
    },
    visible: { x: 0, opacity: 1 },
  }

  return (
    <GitCommandWrapper style={style} ref={ref}>
      {inView ? (
        <>
          <motion.span
            variants={commandVariants}
            initial="initial"
            animate="visible"
            transition={{ duration: 0.7, cubicBezier: [0.16, 1, 0.3, 1] }}
          >
            <TerminalIcon />
          </motion.span>
          <span className="command-text">
            <Typist
              startDelay={800}
              cursor={{
                show: false,
                blink: false,
                element: '|',
                hideWhenDone: true,
                hideWhenDoneDelay: 500,
              }}
              delayGenerator={() => 7}
            >
              {text}
            </Typist>
          </span>
        </>
      ) : null}
    </GitCommandWrapper>
  )
}

export const GitCurve = ({ className, style }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  const pathVariants = {
    initial: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
    },
  }

  return (
    <div ref={ref} style={{ minHeight: 72 }}>
      {inView ? (
        <svg
          width="50"
          height="64"
          viewBox="0 0 50 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginLeft: -1,
            ...style,
          }}
          className={className}
        >
          <motion.path
            d="M1 0C1 17 9 20 23.5 29.5C38 39 49 43.5 49 63.5"
            stroke="#B1B5C4"
            variants={pathVariants}
            initial="initial"
            animate="visible"
            transition={{ duration: 0.7, cubicBezier: [0.16, 1, 0.3, 1] }}
          />
        </svg>
      ) : null}
    </div>
  )
}
