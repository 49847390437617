import React from 'react'
import { Element } from 'react-scroll'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import { TransitionRound, GitLine, GitCommand } from 'components'
import { ContactWrapper } from './Contact.style'
import ContactCard from './ContactCard'

import EmailIcon from 'static/gmail-icon.svg'
import UpworkIcon from 'static/upwork-icon.svg'
import GumroadIcon from 'static/gumroad.svg'
import LinkedInIcon from 'static/linkedin-icon.svg'
import GitHubIcon from 'static/github-icon.svg'

export const Contact = () => {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true })

  const LinesPushAll = () => {
    const linesVariants = {
      initial: {
        pathLength: 0,
      },
      visible: { pathLength: 1 },
    }

    return (
      <svg
        width="750"
        height="252"
        viewBox="0 0 898 252"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lines-push-all"
      >
        <motion.path
          variants={linesVariants}
          initial="initial"
          animate="visible"
          transition={{
            duration: 0.7,
            delay: 1.5,
            cubicBezier: [0.16, 1, 0.3, 1],
          }}
          d="M447 20C447 104.051 115.015 251 9.91232e-06 251"
          stroke="url(#paint0_linear)"
        />
        <motion.path
          variants={linesVariants}
          initial="initial"
          animate="visible"
          transition={{
            duration: 0.7,
            delay: 1.5,
            cubicBezier: [0.16, 1, 0.3, 1],
          }}
          d="M447 20C447 104.051 284.349 251 228 251"
          stroke="url(#paint1_linear)"
        />
        <motion.path
          variants={linesVariants}
          initial="initial"
          animate="visible"
          transition={{
            duration: 0.7,
            delay: 1.5,
            cubicBezier: [0.16, 1, 0.3, 1],
          }}
          d="M447 20C447 104.051 623.019 251 684 251"
          stroke="url(#paint2_linear)"
        />
        <motion.path
          variants={linesVariants}
          initial="initial"
          animate="visible"
          transition={{
            duration: 0.7,
            delay: 1.5,
            cubicBezier: [0.16, 1, 0.3, 1],
          }}
          d="M447 20C447 104.051 781.956 251 898 251"
          stroke="url(#paint3_linear)"
        />
        <motion.path
          variants={linesVariants}
          initial="initial"
          animate="visible"
          transition={{
            duration: 0.7,
            delay: 1.5,
            cubicBezier: [0.16, 1, 0.3, 1],
          }}
          d="M447 0V251"
          stroke="url(#paint4_radial)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="346.5"
            y1="111.623"
            x2="-7.77423"
            y2="227.162"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B1B5C3" />
            <stop offset="1" stop-color="#3772FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="397.762"
            y1="111.623"
            x2="210.511"
            y2="141.542"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B1B5C3" />
            <stop offset="1" stop-color="#3772FF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="500.285"
            y1="111.623"
            x2="702.067"
            y2="146.514"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B1B5C3" />
            <stop offset="1" stop-color="#3772FF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="548.399"
            y1="111.623"
            x2="905.227"
            y2="229.036"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B1B5C3" />
            <stop offset="1" stop-color="#3772FF" />
          </linearGradient>
          <radialGradient
            id="paint4_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(447) rotate(89.9999) scale(251 16565.9)"
          >
            <stop offset="0.384064" stop-color="#B1B5C3" />
            <stop offset="1" stop-color="#45B36B" />
          </radialGradient>
        </defs>
      </svg>
    )
  }

  const headerVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Element name="contact">
      <ContactWrapper ref={ref}>
        <TransitionRound
          style={{ transform: 'rotate(180deg)', top: -8, bottom: 'auto' }}
        />
        <div className="line-centered">
          <GitLine
            height={155}
            style={{ zIndex: 2, marginLeft: -1, marginTop: -7 }}
          />
        </div>
        {inView ? (
          <>
            <motion.div
              className="contact-header"
              variants={headerVariants}
              initial="initial"
              animate="visible"
              transition={{ duration: 0.7, cubicBezier: [0.16, 1, 0.3, 1] }}
            >
              <div>
                <h2>Contact Me</h2>
                <p className="sub-header">
                  Whether you just want a chat, or start a <br />
                  project, feel free contact me by using one
                  <br /> of the links below.
                </p>
              </div>
            </motion.div>
            <div className="line-centered">
              <GitLine
                height={25}
                style={{ zIndex: 2, marginLeft: -2, marginTop: -2 }}
              />
            </div>
            <div className="contact-command-wrapper">
              <div className="contact-command">
                <GitCommand text="git push origin --all" />
              </div>
            </div>
            <div className="line-centered">
              <LinesPushAll className="lines-push-all" />
            </div>
            <div className="contact-cards">
              <motion.div
                className="contact-cards-content"
                variants={headerVariants}
                initial="initial"
                animate="visible"
                transition={{
                  delay: 2,
                  duration: 0.7,
                  cubicBezier: [0.16, 1, 0.3, 1],
                }}
              >
                <ContactCard
                  title="LinkedIn"
                  url="https://www.linkedin.com/in/viktor-serdjukov-76a6b9146/"
                  icon={LinkedInIcon}
                />
                <ContactCard
                  title="GitHub"
                  url="https://github.com/cl0udsurfer"
                  icon={GitHubIcon}
                />
                <ContactCard
                  title="Email"
                  url="viktor@viktorsdk.com"
                  icon={EmailIcon}
                  color="#45B36B"
                />
                <ContactCard
                  title="Gumroad"
                  url="https://gumroad.com/viktorsdk"
                  icon={GumroadIcon}
                />
                <ContactCard
                  title="Upwork"
                  url="https://www.upwork.com/freelancers/~01c60dce05a76a7b32"
                  icon={UpworkIcon}
                />
              </motion.div>
            </div>
          </>
        ) : null}
      </ContactWrapper>
    </Element>
  )
}
