import styled from 'styled-components'
import { motion } from 'framer-motion'
import { neutral, lightBlue } from 'styles'

import GradientBgPNG from 'static/gradient.png'

export const WorksWrapper = styled.div`
  margin-top: -2px;
  position: relative;
  min-height: 500px;
  background-color: ${({ theme }) => theme.backgroundDark};
  overflow: hidden;

  .works-left {
    width: 50%;
    margin-top: -2px;
  }

  .works-right {
    display: flex;
    align-items: center;
  }

  .feature-works-content {
    margin-left: 2.5rem;
    margin-top: -0.25rem;
  }

  .feature-works-header {
    margin-top: 0.5rem;
    margin-left: 1.5rem;

    h2 {
      color: ${neutral[200]};
    }

    .highlight {
      background-image: radial-gradient(
        35.12% 1864.16% at 7.09% 4.38%,
        #4bc9f0 0%,
        #3772ff 100%
      );

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0.25rem;
    }
  }

  .project-menu {
    margin-left: 1.5rem;
  }
`
export const GradientBG = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  background-image: url(${GradientBgPNG});
  background-repeat: no-repeat;
  mix-blend-mode: overlay;

  transform: scaleX(-1);
`
export const ProjectCardWrapper = styled.div`
  position: relative;
  border: 1px solid ${neutral[600]};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  color: ${neutral[500]};
  font-weight: 600;
  cursor: pointer;
  max-width: 300px;
  margin-top: -2px;
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

  .title {
    margin: 0 1rem;
    width: 180px;
  }

  &:hover {
    transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    color: ${neutral[300]};
    border-color: ${neutral[500]};

    .arrow-head {
      transform: translateX(0px);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
    .arrow-body {
      transform: scaleX(1);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
  }

  &.active {
    .arrow-head {
      transform: translateX(0px);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
    .arrow-body {
      transform: scaleX(1);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }

    .title {
      color: ${neutral[100]};
    }

    border-color: ${neutral[400]};
  }
`
