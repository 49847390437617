import styled from 'styled-components'
import { motion } from 'framer-motion'

import GradientBgPNG from 'static/gradient.png'

export const HeroWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundDark}!important;
  color: ${({ theme }) => theme.textColorDark};
  height: 95vh;
  max-height: 926px;
  overflow: hidden;

  .hero-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 1050px) {
    height: 100vh;

    .hero-content {
      display: block;
    }
  }
`

export const TextWrapper = styled.div`
  width: 50%;
  white-space: wrap;
  z-index: 5;
  word-break: break-word;

  h1 {
    display: inline-block;
    background: rgba(23, 23, 25, 0.6);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: 0.2rem 0;
    word-break: break-word;
  }

  h1,
  p,
  .buttons {
    padding: 0.5rem 1rem;
    z-index: 5 !important;
    word-break: break-word;
  }

  p {
    margin: 0.5rem 0;
  }

  .highlight {
    background-image: radial-gradient(
      138.12% 1864.16% at 7.09% 4.38%,
      #6941ff 0%,
      #ff5f7a 30.87%,
      #ffa113 100%
    );
    -webkit-box-decoration-break: clone;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    word-break: break-word;
  }

  .buttons {
    display: flex;

    button {
      margin-right: 1rem;
    }
  }

  @media (max-width: 1050px) {
    width: 100%;
    margin-top: 100px;
    text-align: center;

    .buttons {
      justify-content: center;
    }
  }
`
export const GradientBG = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background-image: url(${GradientBgPNG});
  background-repeat: no-repeat;
  mix-blend-mode: overlay;

  @media (max-width: 1050px) {
    width: 100%;
  } ;
`
export const GlobeWrapper = styled(motion.div)`
  z-index: 1;
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1050px) {
    width: 100%;
    margin-top: -50px;
  }
`
