import React, { useEffect, useState } from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import useWindowSize from 'hooks/useWindowSize'

import { UrlContent, SidebarContent, MainWindowContent } from './Content'
import { layoutAnimations, containerAnimations } from './animations'

import {
  AnimatedScreenWrapper,
  UrlBar,
  Sidebar,
  MainWindow,
} from './AnimatedScreen.style'

export const AnimatedScreen = ({ currentAboutSection, currentProject }) => {
  const [currentSection, setCurrentSection] = useState(null)
  const animation = useAnimation()
  const [ref, inView] = useInView({ threshold: 0.1 })
  const [scrollY, setScrollY] = useState(0)

  const { width } = useWindowSize()

  useEffect(() => {
    if (inView) {
      animation.start('animate')
    }

    const handleScroll = () => {
      setScrollY(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll, false)

    if (width < 850) {
      if (scrollY > 600 && scrollY < 3900) {
        animation.start('mobile')
      } else {
        animation.start('disappear')
      }
    }

    if (scrollY > 2900) {
      animation.start('transform')
      if (width > 850) {
        animation.start('moveToProject')
      } else {
        animation.start('moveToProjectMobile')
      }
      setCurrentSection(null)
    } else {
      animation.start('moveBackMobile')
      animation.start('moveBack')
      animation.start('original')
      setCurrentSection(currentAboutSection)
    }

    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [scrollY, animation, inView, width])

  return (
    <>
      {width > 850 ? (
        <AnimatedScreenWrapper
          variants={layoutAnimations.variantGlobal}
          initial="initial"
          animate={animation}
          ref={ref}
        >
          <UrlBar
            initial="initial"
            animate={animation}
            variants={layoutAnimations.variantUrl}
          >
            {inView ? (
              <UrlContent
                currentSection={currentSection}
                currentProject={currentProject}
              />
            ) : null}
          </UrlBar>
          <Sidebar
            initial="initial"
            animate={animation}
            variants={layoutAnimations.variantSidebar}
          >
            {inView ? (
              <SidebarContent
                currentSection={currentSection}
                currentProject={currentProject}
              />
            ) : null}
          </Sidebar>
          <MainWindow
            initial="initial"
            animate={animation}
            variants={layoutAnimations.variantMainWindow}
          >
            {inView ? (
              <MainWindowContent
                currentSection={currentSection}
                currentProject={currentProject}
              />
            ) : null}
          </MainWindow>
        </AnimatedScreenWrapper>
      ) : (
        <motion.div
          variants={containerAnimations}
          animate={animation}
          style={{
            zIndex: 9999,
            transform: 'scale(0.7)',
            marginLeft: -100,
            marginBottom: -55,
          }}
        >
          <AnimatedScreenWrapper
            variants={layoutAnimations.variantGlobal}
            initial="initial"
            animate={animation}
            ref={ref}
          >
            <UrlBar
              initial="initial"
              animate={animation}
              variants={layoutAnimations.variantUrl}
            >
              {inView ? (
                <UrlContent
                  currentSection={currentSection}
                  currentProject={currentProject}
                />
              ) : null}
            </UrlBar>
            <Sidebar
              initial="initial"
              animate={animation}
              variants={layoutAnimations.variantSidebar}
            >
              {inView ? (
                <SidebarContent
                  currentSection={currentSection}
                  currentProject={currentProject}
                />
              ) : null}
            </Sidebar>
            <MainWindow
              initial="initial"
              animate={animation}
              variants={layoutAnimations.variantMainWindow}
            >
              {inView ? (
                <MainWindowContent
                  currentSection={currentSection}
                  currentProject={currentProject}
                />
              ) : null}
            </MainWindow>
          </AnimatedScreenWrapper>
        </motion.div>
      )}
    </>
  )
}
