import React from 'react'

import { ContactCardWrapper } from './Contact.style'
import ContactIcon from 'static/contact-icon.png'

const ContactCard = ({ color, title = 'Title', url = 'url', icon }) => {
  return (
    <ContactCardWrapper
      color={color}
      href={url.includes('@') ? `mailto:${url}` : url}
      target={url.includes('@') ? '' : '_blank'}
    >
      <div className="card-icon">
        <img src={icon} />
      </div>
      <div className="card-text">
        <span className="card-header">{title}</span>
        <span className="card-url">{url}</span>
      </div>
    </ContactCardWrapper>
  )
}

export default ContactCard
