export const blue = {
  600: '#245ccb',
  500: '#3772FF',
}

export const darkBlue = {
  500: '#21242C',
}

export const purple = {
  500: '#9757D7',
}

export const red = {
  500: '#EF466F',
}

export const green = {
  500: '#45B26B',
}

export const lightBlue = {
  500: '#4BC9F0',
}

export const yellow = {
  500: '#FFD166',
}

export const neutral = {
  800: '#141416',
  700: '#23262F',
  600: '#353945',
  500: '#777E90',
  400: '#B1B5C3',
  300: '#E6E8EC',
  200: '#F4F5F6',
  100: '#FCFCFD',
}
