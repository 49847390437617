import React from 'react'
import { motion } from 'framer-motion'
import { Element } from 'react-scroll'
import { useInView } from 'react-intersection-observer'

import { GitLine, ArrowRightAnimated } from 'components'
import { BlogWrapper } from './Blog.style'
import BlogCard from './BlogCard'
import useWindowSize from 'hooks/useWindowSize'

import BlogSampleImage from 'static/blog-image.png'
import PaticleBlogImage from 'static/particle-blog.png'

import { BlogPattern1, BlogPattern2 } from './BlogPatterns'

export const Blog = () => {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true })

  const { width } = useWindowSize()

  const BlogLines = ({ style, className }) => {
    return (
      <svg
        width="584"
        height="115"
        viewBox="0 0 584 115"
        fill="none"
        style={style}
        className={className}
      >
        <motion.path
          initial="initial"
          animate="visible"
          d="M292 61V1.53143e-06"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M292,61 C292,78 292,78 275,78"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M292 61C292 78 292 78 309 78"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M275 78L18 78"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M309,78 L566,78"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M566,78 C583,78 583,78 583,95"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M18.002 78.002C1.00198 78 1.00198 78 0.999998 95"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M1 95L1 115"
          stroke="#B1B5C4"
        />
        <motion.path
          initial="initial"
          animate="visible"
          d="M583 95V115"
          stroke="#B1B5C4"
        />
      </svg>
    )
  }

  const headerVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Element name="blog">
      <BlogWrapper ref={ref}>
        <div className="line-centered">
          <GitLine height={100} style={{ marginLeft: -3 }} />
        </div>
        {inView ? (
          <>
            <motion.div
              className="blog-header"
              variants={headerVariants}
              initial="initial"
              animate="visible"
              transition={{ duration: 0.7, cubicBezier: [0.16, 1, 0.3, 1] }}
            >
              <h2>Most Recent</h2>
              <div className="highlight-container">
                <a className="highlight">Articles & Tutorials</a>
                <ArrowRightAnimated
                  style={{ display: 'flex' }}
                  color="#ef466f"
                />
              </div>
            </motion.div>
            <div className="line-centered">
              <BlogLines className="blog-lines" />
            </div>
            <div className="blog-posts">
              <BlogCard
                title="Create particles from any image"
                description="How to transform any image into interactive particles, with pure JavaScript."
                date="1 week ago"
                img={PaticleBlogImage}
              />
              <BlogCard
                title="Using Storytelling to Design UI"
                description="Aligning design, animations and content to tell a compelling story."
                date="2 days ago"
                img={BlogSampleImage}
              />
            </div>
            <div className="line-centered">
              <BlogLines
                style={{ transform: 'rotate(180deg)', width: '100%' }}
                className="blog-lines"
              />
            </div>
          </>
        ) : null}
        {width >= 800 ? (
          <>
            <BlogPattern1 className="blog-pattern-1" />
            <BlogPattern2 className="blog-pattern-2" />
          </>
        ) : null}
      </BlogWrapper>
    </Element>
  )
}
