import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { motion } from 'framer-motion'
import Typing from 'react-typing-animation'

import ProfilePic from 'static/profile-pic.png'
import Generic from 'static/generic.png'

import couchnight from 'static/couchnight.png'
import developper from 'static/developper.png'
import hyperse from 'static/hyperse.png'
import reactmini from 'static/reactmini.png'
import microservice from 'static/microservice.png'

import { aboutContentVariants } from './animations'

import {
  SidebarContentWrapper,
  SidebarItem,
  MainWindowContentWrapper,
  AboutContent,
  ExperienceContent,
  TechStackContent,
} from './AnimatedScreen.style'

export const UrlContent = ({ currentSection, currentProject }) => {
  const [url, setUrl] = useState(null)
  const [typing, setTyping] = useState(false)

  const done = () => {
    setTimeout(() => {
      setTyping(false)
    }, 1000)
  }

  useEffect(() => {
    if (currentSection) {
      setUrl('https://www.linkedin.com/in/viktor-serdjukov-76a6b9146')
    } else {
      setUrl(currentProject.url)
    }

    setTyping(true)
  }, [currentProject, url, setUrl])

  return typing && url ? (
    <a href={url} target="_blank">
      <Typing
        startDelay={800}
        speed={5}
        startDelay={500}
        hideCursor
        onFinishedTyping={() => done()}
      >
        {url}
      </Typing>
    </a>
  ) : (
    <a href={url} target="_blank">
      {url}
    </a>
  )
}

export const SidebarContent = ({ currentSection, currentProject }) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const [project, setProject] = useState(null)

  useEffect(() => {
    setProject(currentProject)
    setIsAnimating(true)
  }, [currentProject, project, setProject])

  const done = () => {
    setTimeout(() => {
      setIsAnimating(false)
    }, 1000)
  }

  const hidden = {
    opacity: 0.5,
    scale: 0.9,
    x: 0,
  }
  const visible = {
    opacity: 1,
    scale: 1,
    x: 5,
  }

  return (
    <SidebarContentWrapper>
      {currentSection ? (
        <>
          <SidebarItem
            animate={currentSection === 'about' ? visible : hidden}
            transition={{ duration: 0.3, cubicBezier: [0.16, 1, 0.3, 1] }}
          >
            <Link spy={true} smooth={true} offset={-350} to="about-about">
              About
            </Link>
          </SidebarItem>
          <SidebarItem
            animate={currentSection === 'experience' ? visible : hidden}
            transition={{ duration: 0.3, cubicBezier: [0.16, 1, 0.3, 1] }}
          >
            <Link to="about-experience" spy={true} smooth={true} offset={-350}>
              Experience
            </Link>
          </SidebarItem>
          <SidebarItem
            animate={currentSection === 'stack' ? visible : hidden}
            transition={{ duration: 0.3, cubicBezier: [0.16, 1, 0.3, 1] }}
          >
            <Link spy={true} smooth={true} offset={-350} to="about-stack">
              Tech Stack
            </Link>
          </SidebarItem>
        </>
      ) : (
        <>
          {isAnimating && project ? (
            <motion.div
              variants={aboutContentVariants}
              initial="initial"
              animate="visible"
              transition={{
                delay: 0.5,
                duration: 0.5,
                cubicBezier: [0.16, 1, 0.3, 1],
              }}
              onAnimationComplete={() => done()}
            >
              <div className="projects-title">{project.title}</div>
              <div className="projects-description">{project.description}</div>
            </motion.div>
          ) : project ? (
            <div>
              <div className="projects-title">{project.title}</div>
              <div className="projects-description">{project.description}</div>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </SidebarContentWrapper>
  )
}

export const MainWindowContent = ({ currentSection, currentProject }) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const [project, setProject] = useState(null)

  const done = () => {
    setTimeout(() => {
      setIsAnimating(false)
    }, 1000)
  }

  useEffect(() => {
    setProject(currentProject)
    setIsAnimating(true)
  }, [currentProject, project, setProject])

  const getProjectImg = id => {
    switch (id) {
      case 0:
        return couchnight
      case 1:
        return reactmini
      case 2:
        return hyperse
      case 3:
        return developper
      case 4:
        return microservice
      default:
        return couchnight
    }
  }

  return (
    <MainWindowContentWrapper>
      {currentSection === 'about' ? (
        <AboutContent>
          <motion.div
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
            }}
          >
            <div className="profile-background"></div>
            <div className="profile-picture">
              <img src={ProfilePic} width={30} height={45} />
            </div>
            <div className="profile-header">
              <div style={{ width: '70%' }}>
                <span className="name">Viktor Serdjukov</span>
                <span className="desc">Software Engineer & Student</span>
                <span className="location">Osnabrück, Germany</span>
              </div>
              {/* <div style={{ width: '30%' }}>
                <span className="current">MDPortals</span>
                <span className="current">University of Osnabrück</span>
              </div> */}
            </div>
          </motion.div>
          <motion.div
            className="profile-info"
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
              delay: 0.2,
            }}
          >
            <span className="info-header">Info</span>
            <span className="info-text">
              Interested in software and technology, economics, philosophy and
              literature. Currently freelancing as a software engineer and doing
              a bachelor's in Computer Science/Philosphy.
            </span>
          </motion.div>
        </AboutContent>
      ) : currentSection === 'experience' ? (
        <ExperienceContent>
          <span className="exp-header">Experience</span>
          <motion.div
            className="entry"
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
            }}
          >
            <div className="entry-img">
              <img src={Generic} width={20} height={20} />
            </div>
            <div className="entry-details">
              <span className="entry-header">Software Engineer</span>
              <span className="entry-location">MDPortals</span>
              <span className="entry-time">Jan 2021 - Present</span>
            </div>
          </motion.div>
          <motion.div
            className="entry"
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
            }}
          >
            <div className="entry-img">
              <img src={Generic} width={20} height={20} />
            </div>
            <div className="entry-details">
              <span className="entry-header">Software Engineer</span>
              <span className="entry-location">Logunion</span>
              <span className="entry-time">Nov 2019 - Dez 2020</span>
            </div>
          </motion.div>
          <motion.div
            className="entry"
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
            }}
          >
            <div className="entry-img">
              <img src={Generic} width={20} height={20} />
            </div>
            <div className="entry-details">
              <span className="entry-header">Freelance Software Engineer</span>
              <span className="entry-location">Remote/Worldwide</span>
              <span className="entry-time">Oct 2018 - Present</span>
            </div>
          </motion.div>
          <span className="exp-header">Education</span>
          <motion.div
            className="entry"
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
              delay: 0.2,
            }}
          >
            <div className="entry-img">
              <img src={Generic} width={20} height={20} />
            </div>
            <div className="entry-details">
              <span className="entry-header">
                B.Sc. Computer Science & Philosophy
              </span>
              <span className="entry-location">University of Osnabrück</span>
              <span className="entry-time">Oct 2020 - Present</span>
            </div>
          </motion.div>
        </ExperienceContent>
      ) : currentSection === 'stack' ? (
        <TechStackContent>
          <span className="exp-header">Knowledge & Skills</span>
          <motion.div
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
            }}
          >
            <span className="exp-subheader">Frontend Technologies</span>
            <div className="exp-list">
              <span className="list-item">JavaScript</span>
              <span className="list-item">TypeScript</span>
              <span className="list-item">React</span>
              <span className="list-item">Gatsby.js</span>
              <span className="list-item">Next.js</span>
              <span className="list-item">Vue.js</span>
            </div>
          </motion.div>
          <motion.div
            variants={aboutContentVariants}
            initial="initial"
            animate="visible"
            transition={{
              duration: 0.5,
              cubicBezier: [0.16, 1, 0.3, 1],
              delay: 0.2,
            }}
          >
            <span className="exp-subheader">Backend Technologies</span>
            <div className="exp-list">
              <span className="list-item">Node.js</span>
              <span className="list-item">AWS</span>
              <span className="list-item">Serverless</span>
              <span className="list-item">MongoDB</span>
              <span className="list-item">PostgreSQL</span>
              <span className="list-item">Nest.js</span>
              <span className="list-item">GraphQL</span>
              <span className="list-item">Docker</span>
              <span className="list-item">CI/CD</span>
            </div>
          </motion.div>
        </TechStackContent>
      ) : currentSection === 'none' ? (
        <div></div>
      ) : (
        <>
          {isAnimating && project ? (
            <motion.div
              variants={aboutContentVariants}
              initial="initial"
              animate="visible"
              transition={{
                delay: 0.5,
                duration: 0.5,
                cubicBezier: [0.16, 1, 0.3, 1],
              }}
              onAnimationComplete={() => done()}
            >
              <img src={getProjectImg(project.id)} className="project-img" />
            </motion.div>
          ) : project ? (
            <>
              <img src={getProjectImg(project.id)} className="project-img" />
            </>
          ) : (
            <div></div>
          )}
        </>
      )}
    </MainWindowContentWrapper>
  )
}
