import React from 'react'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { typeScale, primaryFont } from 'styles'

export const BUTTON_MODIFIERS = {
  small: () => `
  `,
}

export const Button = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  border-radius: 90px;
  border: 1px solid ${props => props.theme.textColorDark};
  cursor: pointer;
  background-color: transparent;
  outline: none;
  color: ${({ theme }) => theme.textColorDark};
  font-family: ${primaryFont};
  font-size: ${typeScale.helperText};
  font-weight: 600;
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

  span {
    margin-left: 0.25rem;
  }

  &:hover {
    .arrow-head {
      transform: translateX(0px);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
    .arrow-body {
      transform: scaleX(1);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
  }
`
export const PrimaryButton = styled(Button)`
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.textColorDark};
  border: 2px solid ${props => props.theme.primaryColor};
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.primaryHoverColor};
    border-color: ${props => props.theme.primaryHoverColor};
  }
}

  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`

Button.PrimaryButton = PrimaryButton

export const SecondaryButton = styled(Button)`
  color: ${props => (props.color ? props.color : props.theme.textColorDark)};
  border: 2px solid ${props => (props.border ? props.border : '#E6E8EC')};
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    color: ${props => props.theme.textHoverColor};
    border-color: ${props => props.theme.secondaryHoverBGColor};
    background-color: ${props => props.theme.secondaryHoverBGColor};

     svg {
       fill:${props => props.theme.textHoverColor};
     }
  }
}

  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`

Button.SecondaryButton = SecondaryButton

const ButtonIcon = ({ children, className }) => {
  return <span className={className}>{children}</span>
}

const Icon = styled(ButtonIcon)`
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`

Button.Icon = Icon
