import React from 'react'
import { useInView } from 'react-intersection-observer'

import { ArrowRightAnimated } from 'components'
import { neutral } from 'styles'
import { BlogCardWrapper } from './Blog.style'

const BlogCard = ({ title, description, date, img }) => {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true })

  const blogVariants = {
    initial: {
      y: 20,
      scale: 0.9,
      opacity: 0,
    },
    visible: {
      y: 0,
      scale: 1,
      opacity: 1,
    },
  }

  return (
    <div ref={ref}>
      {inView ? (
        <BlogCardWrapper
          variants={blogVariants}
          initial="initial"
          animate="visible"
          transition={{ duration: 0.5, cubicBezier: [0.16, 1, 0.3, 1] }}
        >
          <div className="card-image">
            <img src={img} width={100} height={100} />
          </div>
          <div className="card-text">
            <span className="card-header">{title}</span>
            <span className="card-description">{description}</span>
            <span className="card-date">{date}</span>
          </div>
          <ArrowRightAnimated color={neutral[500]} />
        </BlogCardWrapper>
      ) : null}
    </div>
  )
}

export default BlogCard
