import styled from 'styled-components'
import { motion } from 'framer-motion'
import { neutral, typeScale } from 'styles'

export const BlogWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: -2px;
  min-height: 577px;

  .blog-header {
    text-align: center;
    margin: 0.5rem 0;

    h2 {
      margin: 0.25rem 0;
    }
  }

  .highlight-container {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .arrow-head {
        transform: translateX(0px);
        transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
      }
      .arrow-body {
        transform: scaleX(1);
        transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
      }
    }
  }

  .highlight {
    background-image: radial-gradient(
      70.12% 1864.16% at 7.09% 4.38%,
      #9757d7 0%,
      #ef466f 100%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    cursor: pointer;
    margin-left: 30px;
    font-weight: 700;
    font-size: ${typeScale.header1};
  }

  .blog-posts {
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 800px) {
    .blog-posts {
      display: block;
    }

    .blog-lines {
      display: none;
    }
  }

  .blog-pattern-1 {
    position: absolute;
    top: 0;
    left: 0;
  }

  .blog-pattern-2 {
    position: absolute;
    top: 300px;
    right: 0;
  }
`

export const BlogCardWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 13px;
  cursor: pointer;
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
  max-width: 500px;
  margin: 0 1.5rem;
  min-height: 133px;

  .card-text {
    display: block;
    margin: 0 18px;
  }

  .card-header {
    display: block;
    color: ${neutral[800]};
    font-weight: 600;
    font-size: ${typeScale.header5};
  }

  .card-description {
    display: block;
    color: ${neutral[600]};
    font-size: ${typeScale.navText};
  }

  .card-date {
    display: block;
    color: ${neutral[500]};
    font-weight: 600;
    font-size: ${typeScale.helperText};
  }

  img {
    background: ${neutral[100]};
    border-radius: 12px;
  }

  &:hover {
    background: #ffffff;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);

    .arrow-head {
      transform: translateX(0px);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
    .arrow-body {
      transform: scaleX(1);
      transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
    }
  }

  @media (max-width: 800px) {
    margin: 1.5rem auto;
  }
`
