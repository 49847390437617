import React from 'react'
import { ThemeProvider } from 'styled-components'

import { ArrowRightAnimated } from 'components'
import { GlobalStyle, defaultTheme } from 'styles'

import { DisclaimerWrapper } from './Disclaimer.style'

export const Disclaimer = ({ children, title }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <DisclaimerWrapper>
        <div className="container">
          <a href="/" className="back-btn">
            <ArrowRightAnimated
              color="#353945"
              style={{ transform: 'rotate(180deg)' }}
            />{' '}
            Go Back
          </a>
          <div className="header">
            <h2>{title}</h2>
          </div>
          <div className="content">{children}</div>
        </div>
      </DisclaimerWrapper>
    </ThemeProvider>
  )
}
