export const containerAnimations = {
  mobile: {
    position: 'fixed',
    bottom: 0,
    left: 10,
    opacity: 1,
    display: 'block',
  },
  disappear: {
    opacity: 0,
    display: 'none',
  },
}

export const layoutAnimations = {
  variantGlobal: {
    initial: {
      opacity: 0,
      x: 25,
      scale: 0.9,
    },
    animate: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.7,
        delay: 0.1,
        transition: { duration: 0.5, cubicBezier: [0.16, 1, 0.3, 1] },
      },
    },
    moveToProject: {
      y: 845,
      width: 630,
      x: -70,
      background: 'rgba(56, 57, 61, 0.57)',
      transition: {
        duration: 0.5,
      },
    },
    moveToProjectMobile: {
      width: 630,
      background: 'rgba(56, 57, 61, 0.57)',
      transition: {
        duration: 0.5,
      },
    },
    moveBack: {
      y: 0,
      width: 555,
      background: '#FFFFFF',
      transition: {
        duration: 0.5,
      },
    },
  },

  variantSidebar: {
    initial: {
      y: 15,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    original: {
      width: 100,
      background: '#FFFFFF',
      transition: {
        duration: 0.7,
        cubicBezier: [0.16, 1, 0.3, 1],
      },
    },
    transform: {
      width: 178,
      background: '#21242c',
      transition: {
        duration: 0.7,
        cubicBezier: [0.16, 1, 0.3, 1],
      },
    },
  },

  variantMainWindow: {
    initial: {
      y: 15,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    original: {
      x: 0,
      background: '#FFFFFF',
      transition: {
        duration: 0.5,
      },
    },
    transform: {
      x: 77,
      background: '#21242c',
      transition: {
        duration: 0.5,
      },
    },
  },

  variantUrl: {
    initial: {
      y: 15,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
    original: {
      background: '#FFFFFF',
      transition: {
        duration: 0.5,
      },
    },
    transform: {
      background: '#21242c',
      transition: {
        duration: 0.5,
      },
    },
  },
}

export const aboutContentVariants = {
  initial: {
    x: 20,
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
  },
}
