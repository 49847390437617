import styled from 'styled-components'

export const ArrowRightAnimatedWrapper = styled.div`
  display: flex;
  align-items: center;

  .arrow-head {
    margin-left: -19px;
    transform: translateX(-4px);
    transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
  }

  .arrow-body {
    transform: scaleX(0);
    transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
  }
`
