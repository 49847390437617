import React from 'react'
import { Element } from 'react-scroll'

import { GitBranch, GitLine, GitCommand, GitCurve } from 'components'
import { neutral } from 'styles'
import Git90 from 'assets/images/git-90.svg'

import projects from 'data/projects.json'

import ProjectCard from './ProjectCard'
import { WorksWrapper, GradientBG } from './Works.style'

export const Works = ({ currentProject, setCurrentProject }) => {
  return (
    <Element name="works">
      <WorksWrapper>
        <GradientBG />
        <div className="container">
          <div className="flex">
            <div className="works-left">
              <div className="content">
                <GitLine height={100} />
              </div>
              <GitCommand text="git checkout -b feature/selected-works" />
              <div className="content">
                <GitLine height={712} style={{ paddingRight: 1 }} />
                <div className="feature-works">
                  <GitCurve className="git-curve" />
                  <div className="feature-works-content">
                    <GitBranch branchName="feature/selected-works" />
                    <div className="content">
                      <GitLine height={160} />
                      <div className="feature-works-header">
                        <h2 className="highlight">Selected Works</h2>
                        <h2>
                          Projects Worth
                          <br /> Mentioning
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="project-menu">
                    {projects.data.map((p, i) => (
                      <>
                        <ProjectCard
                          title={p.title}
                          isActive={
                            p.title === currentProject.title ? true : false
                          }
                          onClick={() => setCurrentProject(projects.data[i])}
                        />
                        <GitLine
                          height={15}
                          style={{
                            marginLeft: '1.5rem',
                            marginTop: -1,
                            background:
                              i >= currentProject.id
                                ? `${neutral[600]}`
                                : `${neutral[300]}`,
                          }}
                        />
                      </>
                    ))}
                    <GitCommand
                      text="git merge feature/selected-works"
                      style={{ marginLeft: '0.5rem' }}
                    />
                  </div>
                  <GitCurve
                    className="git-curve reversed-project"
                    style={{ marginLeft: -1 }}
                  />
                </div>
              </div>
              <GitBranch />
              <GitLine height={75} />
              <div className="flex">
                <Git90
                  style={{
                    marginLeft: 6,
                    marginTop: -4,
                    transform: 'scaleX(0.94)',
                  }}
                />
                <GitLine
                  className="vertical"
                  style={{
                    marginTop: 12,
                    marginLeft: 0,
                  }}
                />
                <Git90
                  style={{
                    marginLeft: -1,
                    marginTop: 11,
                    transform: 'scale(-0.96, -1)',
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="flex"
            style={{ width: '100%', justifyContent: 'center' }}
          >
            <GitLine
              height={75}
              style={{ marginLeft: -3, marginTop: -5, marginBottom: -2 }}
            />
          </div>
        </div>
      </WorksWrapper>
    </Element>
  )
}
