import React from 'react'

export const BlogPattern1 = ({ className }) => (
  <svg
    fill="none"
    height="428"
    viewBox="0 0 257 428"
    width="257"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <filter
      id="a"
      color-interpolation-filters="sRGB"
      filterUnits="userSpaceOnUse"
      height="206"
      width="186"
      x=".5"
      y="150.5"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="2.76726" />
      <feGaussianBlur stdDeviation="1.1069" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
      />
      <feBlend
        in2="BackgroundImageFix"
        mode="normal"
        result="effect1_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="6.6501" />
      <feGaussianBlur stdDeviation="2.66004" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04317 0"
      />
      <feBlend
        in2="effect1_dropShadow"
        mode="normal"
        result="effect2_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="12.5216" />
      <feGaussianBlur stdDeviation="5.00862" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0339075 0"
      />
      <feBlend
        in2="effect2_dropShadow"
        mode="normal"
        result="effect3_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="22.3363" />
      <feGaussianBlur stdDeviation="8.93452" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282784 0"
      />
      <feBlend
        in2="effect3_dropShadow"
        mode="normal"
        result="effect4_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="41.7776" />
      <feGaussianBlur stdDeviation="16.711" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0235573 0"
      />
      <feBlend
        in2="effect4_dropShadow"
        mode="normal"
        result="effect5_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="100" />
      <feGaussianBlur stdDeviation="40" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0174624 0"
      />
      <feBlend
        in2="effect5_dropShadow"
        mode="normal"
        result="effect6_dropShadow"
      />
      <feBlend
        in="SourceGraphic"
        in2="effect6_dropShadow"
        mode="normal"
        result="shape"
      />
    </filter>
    <g opacity=".1" stroke="#000">
      <path d="m81 227h25v25h-25z" />
      <path d="m31 76h25v25h-25z" />
      <path d="m56 76h25v25h-25z" />
      <path d="m81 126h25v25h-25z" />
      <path d="m81 151h25v25h-25z" />
      <path d="m131 126h25v25h-25z" />
      <path d="m31 226h25v25h-25z" />
      <path d="m6 402h25v25h-25z" />
      <path d="m106 227h25v25h-25z" />
      <path d="m231 202h25v25h-25z" />
      <path d="m131 302h25v25h-25z" />
      <path d="m181 302h25v25h-25z" />
      <path d="m156 302h25v25h-25z" />
      <path d="m156 327h25v25h-25z" />
      <path d="m106 202h50v25h-50z" />
      <path d="m6 26h25v25h-25z" />
      <path d="m31 51h25v25h-25z" />
      <path d="m6 1h25v25h-25z" />
      <path d="m81 252h25v25h-25z" />
    </g>
    <g filter="url(#a)">
      <path d="m81 151h25v25h-25z" fill="#fff" />
      <path d="m81 151h25v25h-25z" stroke="#e2e2e2" />
    </g>
  </svg>
)

export const BlogPattern2 = ({ className }) => (
  <svg
    fill="none"
    height="432"
    viewBox="0 0 336 432"
    width="336"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <filter
      id="a"
      color-interpolation-filters="sRGB"
      filterUnits="userSpaceOnUse"
      height="231"
      width="186"
      x="149.5"
      y=".5"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="2.76726" />
      <feGaussianBlur stdDeviation="1.1069" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
      />
      <feBlend
        in2="BackgroundImageFix"
        mode="normal"
        result="effect1_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="6.6501" />
      <feGaussianBlur stdDeviation="2.66004" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04317 0"
      />
      <feBlend
        in2="effect1_dropShadow"
        mode="normal"
        result="effect2_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="12.5216" />
      <feGaussianBlur stdDeviation="5.00862" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0339075 0"
      />
      <feBlend
        in2="effect2_dropShadow"
        mode="normal"
        result="effect3_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="22.3363" />
      <feGaussianBlur stdDeviation="8.93452" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282784 0"
      />
      <feBlend
        in2="effect3_dropShadow"
        mode="normal"
        result="effect4_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="41.7776" />
      <feGaussianBlur stdDeviation="16.711" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0235573 0"
      />
      <feBlend
        in2="effect4_dropShadow"
        mode="normal"
        result="effect5_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="100" />
      <feGaussianBlur stdDeviation="40" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0174624 0"
      />
      <feBlend
        in2="effect5_dropShadow"
        mode="normal"
        result="effect6_dropShadow"
      />
      <feBlend
        in="SourceGraphic"
        in2="effect6_dropShadow"
        mode="normal"
        result="shape"
      />
    </filter>
    <filter
      id="b"
      color-interpolation-filters="sRGB"
      filterUnits="userSpaceOnUse"
      height="206"
      width="186"
      x=".5"
      y="225.5"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="2.76726" />
      <feGaussianBlur stdDeviation="1.1069" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
      />
      <feBlend
        in2="BackgroundImageFix"
        mode="normal"
        result="effect1_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="6.6501" />
      <feGaussianBlur stdDeviation="2.66004" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04317 0"
      />
      <feBlend
        in2="effect1_dropShadow"
        mode="normal"
        result="effect2_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="12.5216" />
      <feGaussianBlur stdDeviation="5.00862" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0339075 0"
      />
      <feBlend
        in2="effect2_dropShadow"
        mode="normal"
        result="effect3_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="22.3363" />
      <feGaussianBlur stdDeviation="8.93452" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282784 0"
      />
      <feBlend
        in2="effect3_dropShadow"
        mode="normal"
        result="effect4_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="41.7776" />
      <feGaussianBlur stdDeviation="16.711" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0235573 0"
      />
      <feBlend
        in2="effect4_dropShadow"
        mode="normal"
        result="effect5_dropShadow"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="100" />
      <feGaussianBlur stdDeviation="40" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0174624 0"
      />
      <feBlend
        in2="effect5_dropShadow"
        mode="normal"
        result="effect6_dropShadow"
      />
      <feBlend
        in="SourceGraphic"
        in2="effect6_dropShadow"
        mode="normal"
        result="shape"
      />
    </filter>
    <g opacity=".1" stroke="#000">
      <path d="m230 226h25v25h-25z" />
      <path d="m255 226h25v25h-25z" />
      <path d="m205 126h25v25h-25z" />
      <path d="m230 51h25v25h-25z" />
      <path d="m230 26h25v25h-25z" />
      <path d="m230 1h25v25h-25z" />
      <path d="m205 226h25v25h-25z" />
      <path d="m81 226h25v25h-25z" />
      <path d="m205 251h25v25h-25z" />
      <path d="m180 251h25v25h-25z" />
      <path d="m81 251h25v25h-25z" />
      <path d="m180 76h25v25h-25z" />
      <path d="m81 276h25v25h-25z" />
      <path d="m31 276h25v25h-25z" />
      <path d="m205 151h50v25h-50z" />
      <path d="m180 176h25v25h-25z" />
    </g>
    <g filter="url(#a)">
      <path d="m230 1h25v50h-25z" fill="#fff" />
      <path d="m230 1h25v50h-25z" stroke="#e2e2e2" />
    </g>
    <g filter="url(#b)">
      <path d="m81 226h25v25h-25z" fill="#fff" />
      <path d="m81 226h25v25h-25z" stroke="#e2e2e2" />
    </g>
  </svg>
)
