import React, { useEffect } from 'react'
import { Element, Link } from 'react-scroll'
import { useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import {
  HeroWrapper,
  TextWrapper,
  GradientBG,
  GlobeWrapper,
} from './Hero.style'

import { Button, ArrowRightAnimated, Globe, TransitionRound } from 'components'

export const Hero = () => {
  const animation = useAnimation()
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true })

  const gradientBgVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.7,
        cubicBezier: [0.16, 1, 0.3, 1],
      },
    },
  }

  const globeVariants = {
    initial: {
      scale: 0.9,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.7,
        delay: 0.25,
        cubicBezier: [0.16, 1, 0.3, 1],
      },
    },
  }

  useEffect(() => {
    if (inView) {
      animation.start('visible')
    }
  }, [inView])

  return (
    <Element name="hero">
      <HeroWrapper ref={ref}>
        <GradientBG
          variants={gradientBgVariants}
          initial="initial"
          animate={animation}
        />
        <div className="container hero-content">
          <TextWrapper>
            <h1>
              Delivering <span className="highlight">Quality Software</span>
            </h1>
            <h1>
              To Every Place <span className="highlight">You Want</span>
            </h1>
            <p>
              As a Software Engineer with an eye for design and user experience,
              I craft quality software from front to back - every aspect
              considered. You can call me your Swiss Army Knife in terms of
              Software.
            </p>
            <div className="buttons">
              <Link to="contact" smooth spy>
                <Button.PrimaryButton>
                  Contact
                  <Button.Icon className="arrow-right-animated">
                    <ArrowRightAnimated />
                  </Button.Icon>
                </Button.PrimaryButton>
              </Link>
              <Link to="about" smooth spy>
                <Button.SecondaryButton>
                  Learn More
                  <Button.Icon className="arrow-right-animated">
                    <ArrowRightAnimated />
                  </Button.Icon>
                </Button.SecondaryButton>
              </Link>
            </div>
          </TextWrapper>
          <GlobeWrapper
            variants={globeVariants}
            initial="initial"
            animate={animation}
          >
            <Globe />
          </GlobeWrapper>
        </div>
        <TransitionRound />
      </HeroWrapper>
    </Element>
  )
}
