import {
  blue,
  darkBlue,
  purple,
  red,
  green,
  lightBlue,
  yellow,
  neutral,
} from './colors'
import { primaryFont, secondaryFont } from './typography'

export const defaultTheme = {
  primaryColor: blue[500],
  primaryHoverColor: blue[600],
  secondaryHoverBGColor: neutral[100],
  primaryActiveColor: blue[600],
  textColor: neutral[500],
  textColorDark: neutral[100],
  textHoverColor: neutral[800],
  primaryFont: primaryFont,
  secondaryFont: secondaryFont,
  backgroundDark: darkBlue[500],
  backgroundLight: neutral[200],
  notificationBg: neutral[700],
}
