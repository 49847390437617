import React from 'react'

import ChevronRight from 'assets/icons/ChevronRight.svg'
import Line from 'assets/icons/Line.svg'

import { ArrowRightAnimatedWrapper } from './Icon.style'

export const ArrowRightAnimated = ({ color, style }) => {
  return (
    <ArrowRightAnimatedWrapper style={style}>
      <Line fill={color ? color : 'white'} className="arrow-body" />
      <ChevronRight fill={color ? color : 'white'} className="arrow-head" />
    </ArrowRightAnimatedWrapper>
  )
}
