import React from 'react'
import styled from 'styled-components'

const TransitionWrapper = styled.div`
  position: absolute;
  bottom: -8px;
  width: 100vw;
  z-index: 1;
`

export const TransitionRound = ({ style }) => {
  return (
    <TransitionWrapper style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        className="position-absolute width-full z-1"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#F4F5F6"></path>
      </svg>
    </TransitionWrapper>
  )
}
