import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import { primaryFont, secondaryFont, typeScale } from './typography'
import { neutral } from './colors'

export const GlobalStyle = createGlobalStyle`
${normalize()}

html {
    box-sizing: border-box;
    font-size: 16px;
    overflow-x: hidden;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: ${primaryFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    background-color: ${({ theme }) => theme.backgroundLight}; 
    position: relative;
}

.main {
    position: relative;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: ${typeScale['hero']};
    font-weight: 700;
}

h2 {
    font-size: ${typeScale['header1']};
    font-weight: 700;
    color: ${neutral[600]};
    line-height: 1.2;
}

h3 {
    font-size: ${typeScale['header2']};
    font-weight: 700;
    color: ${neutral[600]};
    line-height: 1.2;
}

p {
    font-weight: 400;
    font-size: ${typeScale['paragraph']};
    color: ${({ theme }) => theme.textColor};
    margin: 0;
}

.container {
    max-width: 1150px;
    padding: 0 1rem;
    margin: 0 auto;
}

.flex {
    display: flex;
    flex-direction: row;
}

.label-window {  
    padding: 12px 16px;
    background: rgba(23, 23, 25, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    width: 350px;
    font-family: ${secondaryFont};
    transition: all cubic-bezier(0.16, 1, 0.3, 1);

    @keyframes scaleAnimation {
        0% {
            transform: scale(0.9);
            -webkit-transform: scale(0.9);
            opacity: 0;
        }
        100% {
            transform: scale(1.0);
            -webkit-transform: scale(1.0);
            opacity: 1;
        }
    }

    animation: scaleAnimation 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    -webkit-animation: scaleAnimation 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.label-company {
    font-weight: 600;
    color: ${neutral[100]};
    font-size: ${typeScale['header4']};
}

.label-desc {
    color: ${neutral[300]};
    font-size: ${typeScale['navText']};
}

.content {
    display: flex;
    flex-direction: row;
  }

  .git-curve {
    margin-top: 2px;
    transform: scaleX(1);

    &.reversed {
      transform: scaleX(-0.98);
    }

    &.reversed-project {
        transform: scaleX(-1.02);
    }
  }

  .line-centered {
    display: flex;
    justify-content: center;
  }

  .screen-wrapper {
      position: relative;
      width: 100%;
  }

  .screen-wrapper-left {
    position: relative;
    width: 50%;
}

  .screen-wrapper-right {
    width: 50%;
    position: relative;
}
`
