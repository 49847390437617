import styled from 'styled-components'

export const DisclaimerWrapper = styled.div`
  margin: 2rem 0;

  .back-btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-weight: 500;

    &:hover {
      .arrow-head {
        transform: translateX(0px);
        transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
      }
      .arrow-body {
        transform: scaleX(1);
        transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
      }
    }
  }

  .header {
    margin: 2rem 0;
  }

  .content {
    display: block;

    h4 {
      color: #353945;
      margin-top: 1rem;
    }
  }
`
