import React, { useEffect, useState } from 'react'
import { Element } from 'react-scroll'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import {
  GitBranch,
  GitLine,
  GitCommand,
  AnimatedScreen,
  GitCurve,
} from 'components'

import { AboutWrapper } from './About.style'
import { variantText } from './animations'
import AboutIcon from 'assets/images/about.svg'
import ExperienceIcon from 'assets/images/experience.svg'
import TechStackIcon from 'assets/images/tech-stack.svg'
import AboutPatterns from 'assets/images/about-patterns.svg'

export const About = ({ currentProject }) => {
  const headerAnimation = useAnimation()
  const interludeAnimation = useAnimation()
  const aboutTextAnimation = useAnimation()
  const expTextAnimation = useAnimation()
  const stackTextAnimation = useAnimation()

  const [currentAboutSection, setCurrentAboutSection] = useState(null)

  const [refAbout, inViewAbout] = useInView({ threshold: 1 })
  const [refExperience, inViewExperience] = useInView({ threshold: 1 })
  const [refStack, inViewStack] = useInView({ threshold: 1 })

  const [refInterlude, inViewInterlude] = useInView({ threshold: 1 })
  const [refHeader, inViewHeader] = useInView({ threshold: 1 })
  const [refAboutText, inViewAboutText] = useInView({ threshold: 0.1 })
  const [refExpText, inViewExpText] = useInView({ threshold: 0.1 })
  const [refStackText, inViewStackText] = useInView({ threshold: 0.1 })

  useEffect(() => {
    if (inViewStack) {
      setCurrentAboutSection('stack')
    } else if (inViewExperience) {
      setCurrentAboutSection('experience')
    } else if (inViewAbout) {
      setCurrentAboutSection('about')
    } else {
      setCurrentAboutSection('none')
    }

    if (inViewHeader) {
      headerAnimation.start('visible')
    }
    if (inViewInterlude) {
      interludeAnimation.start('visible')
    }
    if (inViewAboutText) {
      aboutTextAnimation.start('visible')
    }
    if (inViewExpText) {
      expTextAnimation.start('visible')
    }
    if (inViewStackText) {
      stackTextAnimation.start('visible')
    }
  }, [
    inViewAbout,
    inViewExperience,
    inViewStack,
    inViewHeader,
    inViewInterlude,
    inViewAboutText,
    inViewExpText,
    inViewStackText,
  ])

  return (
    <Element name="about">
      <AboutWrapper>
        <div className="container">
          <div className="flex">
            <div className="about-left">
              <GitBranch />
              <div className="content" ref={refInterlude}>
                <GitLine height={200} />
                {inViewInterlude ? (
                  <motion.div
                    className="text"
                    variants={variantText}
                    animate={interludeAnimation}
                    initial="hidden"
                  >
                    <h3 className="interlude-text">
                      &bdquo;If we want users to like our software, we should
                      design it to behave like a likeable person.&ldquo;
                    </h3>
                    <span className="interlude-author">- Alan Cooper</span>
                  </motion.div>
                ) : null}
              </div>
              <GitCommand text="git checkout -b feature/about-me" />
              <div className="content">
                <GitLine height={2082} style={{ paddingRight: 1 }} />
                <div className="feature-about">
                  <GitCurve className="git-curve" />
                  <div className="feature-about-content">
                    <GitBranch branchName="feature/about-me" />
                    <div className="content">
                      <GitLine height={300} />
                      <motion.div
                        className="feature-about-header"
                        ref={refHeader}
                        variants={variantText}
                        animate={headerAnimation}
                        initial="hidden"
                      >
                        <h2 className="highlight">About Me</h2>
                        <h2>
                          Your Partner in
                          <br /> Web Technology
                        </h2>
                      </motion.div>
                    </div>
                    <div className="content">
                      <div>
                        <AboutIcon className="about-sub-icon" />
                        <GitLine height={600} />
                      </div>
                      <Element
                        name="about-about"
                        className="feature-about-sub-text"
                      >
                        <div ref={refAboutText}>
                          {inViewAboutText ? (
                            <motion.div
                              ref={refAbout}
                              variants={variantText}
                              animate={aboutTextAnimation}
                              initial="hidden"
                            >
                              As a{' '}
                              <span className="highlight-text">
                                full-stack software engineer
                              </span>{' '}
                              I enjoy designing highly interactive user
                              interfaces that enthuse customers and creating
                              scalable, reliable backend solutions - but also
                              caring about the other parts that are needed for a{' '}
                              <span className="highlight-text">
                                successful software project:
                              </span>{' '}
                              Consistency in design, good planning of UI and
                              thinking how it affects the UX, Search Engine
                              Optimization and maintaining the software after
                              initial release.
                            </motion.div>
                          ) : (
                            <div
                              style={{ minWidth: 300, minHeight: 300 }}
                            ></div>
                          )}
                        </div>
                      </Element>
                    </div>
                    <div className="content">
                      <div>
                        <ExperienceIcon className="about-sub-icon" />
                        <GitLine height={600} />
                      </div>
                      <Element
                        name="about-experience"
                        className="feature-about-sub-text"
                      >
                        <div ref={refExpText}>
                          {inViewExpText ? (
                            <motion.div
                              ref={refExperience}
                              variants={variantText}
                              animate={expTextAnimation}
                              initial="hidden"
                            >
                              While I was working for various companies across
                              the globe, I've had the privilege to learn about
                              their unique challenges of creating software
                              that's{' '}
                              <span className="highlight-text">
                                best suited for their business
                              </span>
                              . Whether it's been in the health care, logistics
                              or finance sector - it's important to know the
                              external influences that may impact the software
                              project, and how the project itself fits into the{' '}
                              <span className="highlight-text">
                                bigger picture
                              </span>
                              .
                            </motion.div>
                          ) : (
                            <div
                              style={{ minWidth: 300, minHeight: 300 }}
                            ></div>
                          )}
                        </div>
                      </Element>
                    </div>
                    <div className="content">
                      <div>
                        <TechStackIcon className="about-sub-icon" />
                        <GitLine height={300} />
                      </div>
                      <Element
                        name="about-stack"
                        className="feature-about-sub-text"
                      >
                        <div ref={refStackText}>
                          {inViewStackText ? (
                            <motion.div
                              ref={refStack}
                              variants={variantText}
                              animate={stackTextAnimation}
                              initial="hidden"
                            >
                              I have{' '}
                              <span className="highlight-text">
                                maintained, developed and launched multiple
                                projects from scratch
                              </span>
                              , carrying the development of it's backend and
                              frontend codebases. My current toolset includes
                              TypeScript/JavaScript, React, Vue, Node.js and all
                              the other various frameworks, libraries and
                              technologies related to them.
                            </motion.div>
                          ) : (
                            <div
                              style={{ minWidth: 300, minHeight: 300 }}
                            ></div>
                          )}
                        </div>
                      </Element>
                    </div>
                    <GitCommand text="git merge feature/about-me" />
                  </div>
                  <GitCurve
                    className="git-curve reversed"
                    style={{ marginLeft: -1 }}
                  />
                </div>
              </div>
            </div>
            <div className="about-right">
              <div className="about-right-absolute">
                <AboutPatterns className="background-pattern" />
                <AboutPatterns className="background-pattern-2" />
              </div>

              <AnimatedScreen
                currentAboutSection={currentAboutSection}
                currentProject={currentProject}
              />
            </div>
          </div>
          <GitBranch />
          <GitLine height={75} />
        </div>
      </AboutWrapper>
    </Element>
  )
}
