import styled from 'styled-components'
import { motion } from 'framer-motion'
import { neutral, typeScale } from 'styles'

export const ConsentWrapper = styled(motion.div)`
  position: fixed;
  bottom: 10px;
  right: 0px;
  margin: 0 20px;
  z-index: 9999;

  span {
    display: flex;
    align-items: center;
    margin-right: 25px;

    svg {
      margin-right: 10px;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 100%;
    max-width: 512px;

    color: ${neutral[500]};
    font-size: ${typeScale.navText};
    background: #fcfcfd;
    box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.2);
    border-radius: 56px;
  }
  .button {
    display: flex;
    flex-direction: row;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.1s;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
  }
  .acceptButton {
    span {
      display: flex;
      align-items: center;
    }
    svg {
      margin-right: 7px;
    }
    padding: 12px 16px;
    color: #fff;
    background: #3772ff;
    border-radius: 90px;
    &:hover {
      background: #245ccb;
    }
  }
  .declineButton {
    color: #111827;
    background: transparent;
    margin-left: 10px;
    margin-right: 5px;

    &:hover {
      color: #425466;
    }
  }
`
