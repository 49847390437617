import React from 'react'
import ReactCookieConsent from 'react-cookie-consent'

import { ConsentWrapper } from './CookieConsent.style'

const CheckFilled = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 29.3334C23.3638 29.3334 29.3333 23.3639 29.3333 16.0001C29.3333 8.63628 23.3638 2.66675 16 2.66675C8.63616 2.66675 2.66663 8.63628 2.66663 16.0001C2.66663 23.3639 8.63616 29.3334 16 29.3334ZM23.3737 12.7072C23.7643 12.3167 23.7643 11.6835 23.3737 11.293C22.9832 10.9024 22.35 10.9024 21.9595 11.293L14.6666 18.5859L11.3737 15.293C10.9832 14.9024 10.35 14.9024 9.95952 15.293C9.569 15.6835 9.569 16.3167 9.95952 16.7072L13.9595 20.7072C14.1471 20.8947 14.4014 21.0001 14.6666 21.0001C14.9318 21.0001 15.1862 20.8947 15.3737 20.7072L23.3737 12.7072Z"
        fill="#45B36B"
      />
    </svg>
  )
}

const DeclineCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#777E91"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z"
        fill="#777E91"
      />
    </svg>
  )
}

const HeartIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99996 3.20638C7.22136 2.4592 6.16429 2 4.99996 2C2.60673 2 0.666626 3.9401 0.666626 6.33333C0.666626 10.5788 5.31352 12.9233 7.20915 13.7031C7.71967 13.9131 8.28025 13.9131 8.79076 13.7031C10.6864 12.9233 15.3333 10.5788 15.3333 6.33333C15.3333 3.9401 13.3932 2 11 2C9.83563 2 8.77856 2.4592 7.99996 3.20638Z"
        fill="#FCFCFD"
      />
    </svg>
  )
}

const CustomAcceptButton = () => {
  return (
    <>
      <HeartIcon />
      Accept
    </>
  )
}

export const CookieConsent = () => {
  return (
    <ConsentWrapper>
      <ReactCookieConsent
        location="bottom"
        buttonText={<CustomAcceptButton />}
        declineButtonText={<DeclineCircle />}
        cookieName="gatsby-gdpr-google-analytics"
        disableStyles={true}
        enableDeclineButton
        flipButtons
        buttonClasses="button acceptButton"
        declineButtonClasses="button declineButton"
        containerClasses="wrapper"
        buttonWrapperClasses="button-wrapper"
      >
        <span>
          <CheckFilled />
          This Website uses Cookies.
        </span>
      </ReactCookieConsent>
    </ConsentWrapper>
  )
}
