import styled from 'styled-components'
import { neutral, secondaryFont, typeScale } from 'styles'

export const GitBranchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${secondaryFont};
  font-size: ${typeScale['helperText']};
  color: ${neutral[500]};
  min-height: 26px;

  .outline {
    margin-right: 0.25rem;
  }

  .branch {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    padding: 2px 8px;

    border: 1px solid ${neutral[400]};
    border-radius: 90px;

    span {
      padding-bottom: 1px;
    }
  }

  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid ${neutral[400]};
  }
`
export const Line = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 7px;
  width: 1px;
  background-color: ${neutral[400]};
  height: ${({ height }) => (height ? height : 50)}px;

  &.vertical {
    height: 1px !important;
    width: 100%;
  }
`
export const GitCommandWrapper = styled.div`
  margin-left: -12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 28px;
  white-space: nowrap;

  span {
    display: flex;
    align-items: center;
  }

  .command-text {
    font-family: ${secondaryFont};
    font-size: ${typeScale['helperText']};
    color: ${neutral[500]};
    padding-bottom: 7px;
    margin-left: 0.5rem;
  }
`
