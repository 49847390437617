import React from 'react'

import { ArrowRightAnimated } from 'components'
import { lightBlue, neutral } from 'styles'

import { ProjectCardWrapper } from './Works.style'

const CheckOutlined = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transition: 'all 0.4s ease-in-out' }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.9428 11.7238C27.4635 12.2445 27.4635 13.0887 26.9428 13.6094L16.2761 24.2761C15.7554 24.7968 14.9112 24.7968 14.3905 24.2761L9.05718 18.9427C8.53648 18.422 8.53648 17.5778 9.05718 17.0571C9.57788 16.5364 10.4221 16.5364 10.9428 17.0571L15.3333 21.4476L25.0572 11.7238C25.5779 11.2031 26.4221 11.2031 26.9428 11.7238Z"
        fill={color ? color : '#B1B5C4'}
        style={{ transition: 'all 0.4s ease-in-out' }}
      />
      <rect
        x="1.5"
        y="1.5"
        width="33"
        height="33"
        rx="16.5"
        stroke={color ? color : '#B1B5C4'}
        stroke-width="3"
        style={{ transition: 'all 0.4s ease-in-out' }}
      />
    </svg>
  )
}

const ProjectCard = ({ title = 'Project', isActive, onClick }) => {
  return (
    <ProjectCardWrapper onClick={onClick} className={isActive ? 'active' : ''}>
      <CheckOutlined
        width={30}
        height={30}
        className="check-outlined"
        fill="blue"
        color={isActive ? `${lightBlue[500]}` : `${neutral[600]}`}
      />
      <span className="title">{title}</span>
      <ArrowRightAnimated
        color={isActive ? `${neutral[100]}` : `${neutral[500]}`}
      />
    </ProjectCardWrapper>
  )
}

export default ProjectCard
