export const primaryFont = '"Poppins", sans-serif'
export const secondaryFont = '"Inconsolata", monospace'

export const typeScale = {
  hero: '2.3rem',
  header1: '1.8rem',
  header2: '1.6rem',
  header3: '1.4rem',
  header4: '1.2rem',
  header5: '1.1rem',
  paragraph: '1rem',
  navText: '0.9rem',
  helperText: '0.8rem',
  copyrightText: '0.7rem',
}
