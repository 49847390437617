import styled from 'styled-components'
import { neutral } from 'styles'

export const AboutWrapper = styled.div`
  margin-top: 50px;
  min-height: 500px;

  .about-left {
    width: 50%;
    position: relative;
  }

  .about-right {
    width: 50%;
    position: relative;
  }

  .about-right-absolute {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }

  .text {
    margin-top: 0.75rem;
    margin-left: 1.5rem;
  }

  .feature-about-content {
    margin-left: 2.5rem;
    margin-top: -0.5rem;
  }

  .feature-about-header {
    margin-top: 0.5rem;
    margin-left: 1.5rem;

    .highlight {
      background-image: radial-gradient(
        40.12% 1864.16% at 7.09% 4.38%,
        #6941ff 0%,
        #ef466f 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0.25rem;
    }
  }

  .about-sub-icon {
    margin-top: 3px;
    margin-left: -2px;
  }

  .feature-about-sub-text {
    margin-left: 1rem;
    color: ${neutral[500]};
    padding-right: 2rem;
  }

  .highlight-text {
    color: ${neutral[600]};
  }

  .background-pattern {
    position: absolute;
    top: 0;
    right: 0;
  }

  .background-pattern-2 {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .interlude-text {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${neutral[500]};
    margin-bottom: 0.5rem;
    max-width: 370px;
  }

  .interlude-author {
    font-size: 0.9rem;
    color: ${neutral[500]};
  }

  @media (max-width: 850px) {
    .about-left {
      width: 100%;
    }

    .about-right {
      width: 0;
    }
  }
`
