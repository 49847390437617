import styled from 'styled-components'
import { neutral, typeScale } from 'styles'

export const ContactWrapper = styled.div`
  position: relative;
  background-color: #141416;
  padding-bottom: 125px;
  min-height: 774px;

  .contact-header {
    margin: 0.5rem 0;
    text-align: center;

    h2 {
      color: ${neutral[100]};
    }
  }

  .sub-header {
    margin-top: 0.5rem;
  }

  .contact-command-wrapper {
    margin: 0.25rem 0 1rem 0;
    position: absolute;
    left: 50%;
  }

  .contact-command {
    margin-left: -0.5rem;
  }

  .lines-push-all {
    margin-top: 0.5rem;
    padding: 0 1rem;
  }

  .contact-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -27px;
  }

  .contact-cards-content {
    display: flex;
    align-items: center;
  }

  @media (max-width: 950px) {
    .contact-cards {
      margin-top: 0;
    }

    .contact-cards-content {
      display: block;
    }

    .lines-push-all {
      display: none;
    }
  }

  @media (max-width: 950px) {
    .contact-cards {
      margin-top: 1.5rem;
    }
  }
`

export const ContactCardWrapper = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 76px;
  border: 1px solid ${({ color }) => (color ? color : '#3772ff')};
  padding: 10px 11px;
  cursor: pointer;
  margin: 0 1rem;
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    border-color: ${neutral[100]};
  }

  .card-text {
    display: block;
    margin: 0 0.5rem;
  }

  .card-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 30px;
      height: 30px;
      overflow: hidden;
    }
  }

  .card-header {
    color: ${neutral[100]};
    font-size: ${typeScale.navText};
    font-weight: 600;
    display: block;
  }

  .card-url {
    color: ${neutral[500]};
    font-size: ${typeScale.copyrightText};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
    white-space: nowrap;
  }

  @media (max-width: 950px) {
    margin-top: 1.5rem;
  }
`
